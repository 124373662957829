.ofertaeducativa_title{
    text-align: center;
    padding-bottom: 20px;
}
.ofertaeducativa_title h1, h2, h3{
    color: var(--wine);
    font-family: Montserrat-Bold;
}
.ofertaeducativa-container{
    padding-top: 50px;
    margin-bottom: 50px;
}
.ofertaeducativa_item a{
    color: var(--gold);
    font-size: 20px;
    text-decoration: none;
    display: flex;
    justify-content: center;
}
.ofertaeducativa-container li{
    color:var(--wine);
}
@media (max-width: 576px){
    .ofertaeducativa-container{
        min-height: 600px;
    }
    .ofertaeducativa_item a{
        padding: 10px 0;
    }
}
@media (min-width:577px) and (max-width:820px){
    .ofertaeducativa-container{
        min-height: 600px;
    }
}