.extensionvinculacion_title{
    text-align: center;
    padding-bottom: 20px;
}
.extensionvinculacion_title h1, h2, h3, h4, h5{
    color: var(--wine);
    font-family: Montserrat-Bold;
}
.extensionvinculacion-container{
    padding-top: 50px;
    margin-bottom: 50px;
}
.extensionvinculacion_item a{
    color: var(--gold);
    font-size: 20px;
    text-decoration: none;
    display: flex;
    justify-content: center;
}
.extensionvinculacion-container li{
    color:var(--wine);
}
@media (max-width: 576px){
    .extensionvinculacion-container{
        min-height: 600px;
    }
    .extensionvinculacion_item a{
        padding: 10px 0;
    }
}
@media (min-width:577px) and (max-width:820px){
    .extensionvinculacion-container{
        min-height: 600px;
    }
}