.preguntasfrecuentes_title{
    text-align: center;
    padding-bottom: 20px;
}
.preguntasfrecuentes_title h1{
    color: var(--wine);
    font-family: Montserrat-Bold;
}
.preguntasfrecuentes-container{
    padding-top: 50px;
    margin-bottom: 50px;
}
.preguntasfrecuentes_item a{
    color: var(--gold);
    font-size: 20px;
    text-decoration: none;
    display: flex;
    justify-content: center;
}
.preguntasfrecuentes-container li{
    color:var(--wine);
}
@media (max-width: 576px){
    .preguntasfrecuentes-container{
        min-height: 600px;
    }
    .preguntasfrecuentes_item a{
        padding: 10px 0;
    }
}
@media (min-width:577px) and (max-width:820px){
    .preguntasfrecuentes-container{
        min-height: 600px;
    }
}